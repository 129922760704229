
import Vue from "vue";
import { Alert, Vehicle } from "@/commons/types";
import Alerter from "@/components/Alerter.vue";
import { latLng } from "leaflet";
import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";
import { DeviceGpsSchema, DeviceDataService } from "@/commons/client";

export default Vue.extend({
  name: "Map",

  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    Alerter,
  },

  data: () => ({
    startDateOpen: false,
    startTimeOpen: false,
    endDateOpen: false,
    endTimeOpen: false,
    vehicleId: "",
    selectedVehicle: null,
    dateFilters: {
      from: new Date().toISOString().split("T")[0],
      fromTime: "00:00",
      to: new Date().toISOString().split("T")[0],
      toTime: "23:59",
    },
    map: {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      center: {
        lat: 44.10245,
        lng: 9.824083,
      },
      zoom: 13,
    },
    marker: {
      draggable: true,
      lat: 44.10245,
      lng: 9.824083,
    },
    points: [] as DeviceGpsSchema[],
    alerts: [] as Alert[],
  }),

  computed: {
    rules() {
      return this.$store.state.genericRules;
    },
    organizationId() {
      return this.$store.state.organizationId;
    },
    vehiclesList() {
      return this.$store.state.vehicles.map((v: Vehicle) => ({
        value: v.vehicle_id,
        label: `${v.plate} - ${v.vehicle_id}`,
      }));
    },
    maxCardWidth(): string {
      /*************************************
       * Return tthe right card length based on
       * current breakpoint.
       *************************************/
      // switch (this.$vuetify.breakpoint.name) {
      //   case 'xs' || 'sm': return 400;
      // } return 550;
      return "100%";
    },
    startDatetime(): string {
      /*************************************
       * Return the start datetime in the
       * format YYYY-MM-DD HH:MM:SS
       *************************************/
      return `${this.dateFilters.from}T${this.dateFilters.fromTime}:00.000000Z`;
    },
    endDatetime(): string {
      /*************************************
       * Return the end datetime in the
       * format YYYY-MM-DD HH:MM:SS
       *************************************/
      return `${this.dateFilters.to}T${this.dateFilters.toTime}:00.000000Z`;
    },
    hasValueStart(): boolean {
      /*************************************
       * Return true if the start datetime
       * is set.
       *************************************/
      return this.dateFilters.from !== "" && this.dateFilters.fromTime !== "";
    },
    hasValueEnd(): boolean {
      /*************************************
       * Return true if the end datetime
       * is set.
       *************************************/
      return this.dateFilters.to !== "" && this.dateFilters.toTime !== "";
    },
    hasValueStartOrEnd(): boolean {
      /*************************************
       * Return true if the start or end
       * datetime is set.
       *************************************/
      return this.hasValueStart || this.hasValueEnd;
    },
    endpoint(): string {
      /*************************************
       * Return the endpoint to use
       *************************************/
      let organizationId = this.$store.state.organizationId;
      let baseEndpoint = `${organizationId}/vehicles/${
        this.vehicleId
      }/data/gps/${this.hasValueStartOrEnd ? "?" : ""}`;
      if (this.hasValueStart) {
        baseEndpoint += `start=${this.startDatetime}${
          this.hasValueEnd ? "&" : ""
        }`;
      }
      if (this.hasValueEnd) {
        baseEndpoint += `end=${this.endDatetime}`;
      }
      return baseEndpoint;
    },
    isValidForm(): boolean {
      /*************************************
       * Return true if the form is valid
       *************************************/
      return (
        this.vehicleId !== "" &&
        this.vehicleId !== undefined &&
        this.hasValueStartOrEnd
      );
    },
  },

  watch: {
    selectedVehicle(newVal) {
      if (newVal && newVal !== this.vehicleId) {
        this.vehicleId = newVal;
        this.getPoints();
      }
    },
  },

  methods: {
    latLng: latLng,
    async getGpsData(): Promise<DeviceGpsSchema[]> {
      if (!this.vehicleId) return [];
      if (!this.isValidForm) {
        this.alerts.push({
          type: "error",
          text: "Compilare il form per vedere i dati",
        });
        return [];
      }
      try {
        return await DeviceDataService.organizationVehiclesDataGpsV1OrganizationsPkVehiclesVehicleIdDataGpsGet(
          this.organizationId,
          this.vehicleId,
          this.dateFilters.from +
            "T" +
            this.dateFilters.fromTime +
            ":00.000000Z",
          this.dateFilters.to + "T" + this.dateFilters.toTime + ":00.000000Z",
          100
        );
      } catch (error) {
        this.alerts.push({
          type: "error",
          text: "Errore nel recupero dei dati",
        });
        return [];
      }
    },
    async getPoints(): Promise<void> {
      this.points = await this.getGpsData();
      if (this.points.length > 0) {
        this.setMapCenter();
      } else {
        console.warn("Nessun punto ricevuto, impossibile aggiornare la mappa.");
      }
    },
    setMapCenter(): void {
      if (this.points.length > 0) {
        this.map.center = {
          lat: this.points[0].lat / 100000,
          lng: this.points[0].lon / 100000,
        };
      }
    },
    initFormFromQueryString(): void {
      this.vehicleId =
        this.$route.query.vehicleId &&
        typeof this.$route.query.vehicleId == "string"
          ? this.$route.query.vehicleId
          : "";
      this.dateFilters.from =
        this.$route.query.from && typeof this.$route.query.from == "string"
          ? this.$route.query.from
          : "";
      this.dateFilters.to =
        this.$route.query.to && typeof this.$route.query.to == "string"
          ? this.$route.query.to
          : "";
    },
  },

  created() {
    this.initFormFromQueryString();
    this.$nextTick(() => {
      const today = new Date().toISOString().split("T")[0];
      this.dateFilters.from = today;
      this.dateFilters.to = today;
    });
  },
});
