
import Vue from "vue";
import { Alert, Vehicle } from "@/commons/types";
import Alerter from "@/components/Alerter.vue";
import { DeviceAlarmSchema, DeviceDataService } from "@/commons/client";
import { getAlarmInfo, AlarmInfo } from "@/constants/alerts";
// import TimeScatter from "@/components/TimeScatter.vue";

export default Vue.extend({
  name: "AlarmGraph",
  components: {
    // TimeScatter,
    Alerter,
  },

  data: () => ({
    startDateOpen: false,
    startTimeOpen: false,
    endDateOpen: false,
    endTimeOpen: false,
    vehicleId: "",
    selectedVehicle: null,
    dateFilters: {
      from: new Date().toISOString().split("T")[0],
      fromTime: "00:00",
      to: new Date().toISOString().split("T")[0],
      toTime: "23:59",
    },
    alarms: [] as DeviceAlarmSchema[],
    alerts: [] as Alert[],
    search: "",
    /*chartData: {
      datasets: [
        {
          label: "label",
          fill: false,
          borderColor: "#f87979",
          backgroundColor: "#f87979",
          data: [] as { x: string; y: number; tooltip: string }[],
        },
      ],
    },
    chartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        tooltip: {
          callbacks: {
            label: (context: any) => {
              return `${context.raw.x}: ${context.raw.tooltip} ${context.raw.y}`;
            },
          },
        },
      },
      scales: {
        x: {
          type: "time",
          display: true,
          min: "2023-05-11 00:00:00",
          max: "2023-05-16 00:00:00",
          time: {
            parser: (date: string) => Date.parse(date.replace("T", " ")),
          },
        },
      },
    },*/
  }),

  computed: {
    rules() {
      return this.$store.state.genericRules;
    },
    organizationId(): number {
      return this.$store.state.organizationId;
    },
    maxCardWidth(): string {
      /*************************************
       * Return tthe right card length based on
       * current breakpoint.
       *************************************/
      // switch (this.$vuetify.breakpoint.name) {
      //   case 'xs' || 'sm': return 400;
      // } return 550;
      return "100%";
    },
    startDatetime(): string {
      /*************************************
       * Return the start datetime in the
       * format YYYY-MM-DD HH:MM:SS
       *************************************/
      return `${this.dateFilters.from}T${this.dateFilters.fromTime}:00.000000Z`;
    },
    endDatetime(): string {
      /*************************************
       * Return the end datetime in the
       * format YYYY-MM-DD HH:MM:SS
       *************************************/
      return `${this.dateFilters.to}T${this.dateFilters.toTime}:00.000000Z`;
    },
    hasValueStart(): boolean {
      /*************************************
       * Return true if the start datetime
       * is set.
       *************************************/
      return this.dateFilters.from !== "" && this.dateFilters.fromTime !== "";
    },
    hasValueEnd(): boolean {
      /*************************************
       * Return true if the end datetime
       * is set.
       *************************************/
      return this.dateFilters.to !== "" && this.dateFilters.toTime !== "";
    },
    hasValueStartOrEnd(): boolean {
      /*************************************
       * Return true if the start or end
       * datetime is set.
       *************************************/
      return this.hasValueStart || this.hasValueEnd;
    },
    endpoint(): string {
      /*************************************
       * Return the endpoint to use
       *************************************/
      let organizationId = this.$store.state.organizationId;
      let baseEndpoint = `${organizationId}/vehicles/${
        this.vehicleId
      }/data/alarms/${this.hasValueStartOrEnd ? "?" : ""}`;
      if (this.hasValueStart) {
        baseEndpoint += `start=${this.startDatetime}${
          this.hasValueEnd ? "&" : ""
        }`;
      }
      if (this.hasValueEnd) {
        baseEndpoint += `end=${this.endDatetime}`;
      }
      return baseEndpoint;
    },
    isValidForm(): boolean {
      /*************************************
       * Return true if the form is valid
       *************************************/
      return (
        this.vehicleId !== "" &&
        this.vehicleId !== undefined &&
        this.hasValueStartOrEnd
      );
    },
    filteredAlarms(): DeviceAlarmSchema[] {
      if (this.search !== "") {
        return this.alarms.filter((alarm) =>
          alarm.alarm_type.toLowerCase().includes(this.search.toLowerCase())
        );
      }
      return this.alarms;
    },
    vehiclesList() {
      return this.$store.state.vehicles.map((v: Vehicle) => ({
        value: v.vehicle_id,
        label: `${v.plate} - ${v.vehicle_id}`,
      }));
    },
    mappedAlarms(): AlarmInfo[] {
      return this.filteredAlarms.map((alarm) => ({
        ...alarm,
        ...getAlarmInfo(alarm.alarm_type),
      }));
    },
  },

  watch: {
    selectedVehicle(newValue) {
      this.vehicleId = newValue;
    },
  },

  methods: {
    localizeDtString(dtString: string): string {
      /*************************************
       * Return the datetime string in the
       * format YYYY-MM-DD HH:MM:SS
       *************************************/
      return new Date(`${dtString}Z`).toLocaleString("it-IT", {
        timeZone: "Europe/Rome",
      });
    },
    async getAlarmData(): Promise<DeviceAlarmSchema[]> {
      if (this.isValidForm) {
        try {
          return await DeviceDataService.organizationVehiclesDataAlarmsV1OrganizationsPkVehiclesVehicleIdDataAlarmsGet(
            this.organizationId,
            this.selectedVehicle || "",
            this.hasValueStart ? this.startDatetime : undefined,
            this.hasValueEnd ? this.endDatetime : undefined
          );
        } catch (err) {
          this.alerts.push({
            type: "error",
            text: "Errore durante il recupero dei dati",
          });
          return [];
        }
      } else {
        this.alerts.push({
          type: "error",
          text: "Compilare tutti i campi",
        });
        return [];
      }
    },
    // convertRecordToXY(record: DeviceAlarmSchema) {
    //   return {
    //     x: record.timestamp,
    //     y: record.alarm_value,
    //     tooltip: record.alarm_type,
    //   };
    // },
    // convertDataToDataset(data: DeviceAlarmSchema[]): void {
    //   // convert data to chartData
    //   this.chartData.datasets[0].label = this.vehicleId;
    //   this.chartData.datasets[0].data = data.map(
    //     (record: DeviceAlarmSchema) => {
    //       return this.convertRecordToXY(record);
    //     }
    //   );
    // },
    async getPoints(): Promise<void> {
      const data = await this.getAlarmData();
      this.alarms = data.sort((a: DeviceAlarmSchema, b: DeviceAlarmSchema) => {
        return Date.parse(a.timestamp || "") - Date.parse(b.timestamp || "");
      });
      // this.chartOptions.scales.x.min = data[0].timestamp.replace("T", " ");
      // this.chartOptions.scales.x.max = data[data.length - 1].timestamp.replace(
      //   "T",
      //   " "
      // );
      // this.convertDataToDataset(data);
    },
    initFormFromQueryString(): void {
      this.vehicleId =
        this.$route.query.vehicleId &&
        typeof this.$route.query.vehicleId == "string"
          ? this.$route.query.vehicleId
          : "";
      this.dateFilters.from =
        this.$route.query.from && typeof this.$route.query.from == "string"
          ? this.$route.query.from
          : "";
      this.dateFilters.to =
        this.$route.query.to && typeof this.$route.query.to == "string"
          ? this.$route.query.to
          : "";
    },
  },

  created() {
    this.initFormFromQueryString();
    this.$nextTick(() => {
      const today = new Date().toISOString().split("T")[0];
      this.dateFilters.from = today;
      this.dateFilters.to = today;
    });
  },
});
