export interface AlarmInfo {
  text: string;
  icon: string;
}

export const AlertMap: Record<string, AlarmInfo> = {
  tamper_vkey: {
    text: "Accensione mezzo / Spegnimento mezzo",
    icon: "mdi-power",
  },
  sgp_tamper_dynamic: {
    text: "Rilevato alcol in partenza",
    icon: "mdi-glass-wine",
  },
  sgp_tamper_static: {
    text: "Rilevato alcol in fase di marcia",
    icon: "mdi-car",
  },
  tamper_anti: {
    text: "Rilevata manomissione dispositivo",
    icon: "mdi-lock-alert",
  },
  tamper_fan: { text: "Anomalia ventole", icon: "mdi-fan" },
};

// Funzione con tipizzazione corretta
export const getAlarmInfo = (alarmType: string): AlarmInfo => {
  return (
    AlertMap[alarmType] || {
      text: "Tipo sconosciuto",
      icon: "mdi-alert-circle",
    }
  );
};
